import React, { useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import { pillars } from '../constants/data';


const BlogSidebar = () => {

    const { id } = useParams();

    

    return (
      
        <>
            <aside className="side-bar sticky-top right">

              
            
            <div className="widget service_menu_nav">
                    <ul>
                      {pillars?.map((item, index) => (
                        <li
                          className={`${
                            id === index.toString() ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link to={`/pillars/${index}`}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <svg
                      width="250"
                      height="70"
                      viewBox="0 0 250 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 38L250 0L210 70L0 38Z"
                        fill="url(#paint0_linear_306_1296)"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_306_1296"
                          x1="118.877"
                          y1="35.552"
                          x2="250.365"
                          y2="35.552"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="1" stopColor="var(--primary)"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
            </aside>   
        </>
    );
};

export default BlogSidebar;