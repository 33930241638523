
import React from 'react';
import CountUp from 'react-countup';

const counterData = [
    {number: "4", title:"Pillars to Peak Performance", styleChange:"col-6"},
    {number: "+100", title:"Happy Athletes", styleChange:"col-6"},
    {number: "+500", title:"Science Based Drills", styleChange:"col-12"},
];

const CounterBlog = () => {
    return (
        <>
            {counterData.map((item, ind)=>(
                <div className={`col-sm-4 m-b30 ${item.styleChange}`} key={ind}>                
                    <div className="counter-box">
                        <h3 className="title counter">
                            {item.number.indexOf("+") > -1 ? "+" : ""}
                            <CountUp end={item.number} duration={3}/>    
                        </h3>
                        <p>{item.title}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CounterBlog;