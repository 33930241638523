import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { pillars } from "../constants/data";

function changeItemBoxed() {
  let bodyClass = document.body.classList;

  if (bodyClass.contains === "boxed") {
    return 3;
  } else {
    return 4;
  }
}

function LatestSlider() {
  return (
    <div className="container">
      <div className="row justify-content-between align-items-center m-b10">
        <div className="col-xl-7">
          <div className="section-head text-center text-md-start">
            <span className="sub-title">OUR PILLARS</span>
            <Link to="/pillars/0">
              <h2 className="title">
                Performance Hub <span>Pillars</span>
              </h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        {pillars?.map((item, ind) => (
          <div
            className="dz-card style-1 col-xl-3 col-md-6"
            key={ind}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="dz-media">
              <Link to={`/pillars/${ind}`}>
                <img
                  src={item.image1}
                  alt=""
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              </Link>
            </div>
            <div className="dz-info">
              <h4
                className="dz-title"
                style={{
                  display: "flex",
                  fontSize: "20px",
                }}
              >
                <Link to={`/pillars/${ind}`}>{item.title}</Link>
              </h4>
              <p>{item.cardDetail}</p>
              <Link to={`/pillars/${ind}`} className="btn btn-primary btn-skew">
                <span>Read More</span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default LatestSlider;
