import React from 'react';
import {Link} from 'react-router-dom';
import { IMAGES,SVGICON } from '../constants/theme';
import { HashLink } from 'react-router-hash-link';


const Footer = () => {
    let update = new Date();
    return (
        <>
            <footer className="site-footer style-1 bg-img-fix footer-action" style={{backgroundImage: "url("+ IMAGES.footerbg +")"}} id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-12">
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-dark">
                                        <Link to={"/"}><img className='select_logo_dark' src={IMAGES.logoHorizontal} alt="" /></Link> 
                                    </div>
                                    <p>Number #1 neuroscience based football program in Australia.</p>
                                    <h6 className="m-b15">Follow Us</h6>
                                    <div className="dz-social-icon style-1">
                                        <ul>									
                                            <li>
                                                <Link target="_blank" to="https://www.instagram.com/performancehub_australia/?hl=es" rel="noreferrer">
                                                    <i className="fab fa-instagram"></i>
                                                </Link>
                                            </li>{" "}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.4s">
                                <div className="widget recent-posts-entry">
                                    <h4 className="footer-title">Quick Links</h4>
                                    <div className="widget-post-bx">
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><HashLink to={"/#about-us"}>About Us</HashLink></h6>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><HashLink to={"/#pillars"}>Pillars</HashLink></h6>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><HashLink to={"/#team"}>Team</HashLink></h6>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><HashLink to={"/services"}>Services</HashLink></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.6s">
                                <div className="widget widget_locations">
                                    <h4 className="footer-title">Address</h4>
                                    <div className="clearfix">
                                        <h6>Melbourne</h6>
                                        <p>169 Rosamond, Rd. 
                                        <br/>
                                        Maribyrnong. Vic</p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 wow fadeInUp" data-wow-delay="0.8s">
                                <div className="widget widget_working">
                                    <h4 className="footer-title">Call Now</h4>
                                    <ul>
                                        <li>
                                        <a href="tel:+61423738161" className='info'>
                                            <i className='fa-solid fa-arrow-right m-r5'></i>
                                            +61-423-738-161
                                        </a>
                                        </li>
                                        <li>
                                        <a href="tel:+61478940397" className='info'>
                                            <i className='fa-solid fa-arrow-right m-r5'></i>
                                            +61-478-940-397
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Footer Bottom Part --> */}
                <div className="container">
                    <div className="footer-bottom">
                        <div className="text-center"> 
                            <span className="copyright-text">Copyright © {update.getFullYear()} PerformanceHub. All rights reserved.</span> 
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;