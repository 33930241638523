import { IMAGES } from "../constants/theme";

const iconBox = [
  { image: IMAGES.aboutlogo1, title: "Respect" },
  { image: IMAGES.aboutlogo2, title: "Enjoyment" },
  { image: IMAGES.aboutlogo3, title: "Discipline" },
  { image: IMAGES.aboutlogo4, title: "Perseverance" },
];

const PerfectTrainers = () => {
  return (
    <>
      <div className="col-lg-12 m-b30 about-content">
        <div className="section-head">
          <span className="sub-title wow fadeInUp" data-wow-delay="0.2s">
            Our Values
          </span>
          <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
            Performance Hub <br />
            <span>Values</span>
          </h2>
          <p className="wow fadeInUp" data-wow-delay="0.6s">
            Our values form a balanced approach to performance that not only
            drives achievement but also supports a positive environment.
          </p>
          <p className="wow fadeInUp" data-wow-delay="0.6s">
            Respect ensures collaboration, enjoyment fuels motivation,
            discipline promotes consistency, and perseverance drives individuals
            to overcome challenges and continue striving for excellence.
          </p>
        </div>
        <div className="row m-t40 m-sm-b20 m-b30">
          {iconBox.map((item, ind) => (
            <div className="col-sm-3 m-sm-b20 m-b30" key={ind}>
              <div className="icon-bx-wraper style-2">
                <div className="icon-content">
                  <h5 className="dz-title"> <span style={{
                    fontSize: "30px",
                    marginRight: "10px",
                  }}>
                    <img src={item.image} alt="" style={{
                      width: '40px',
                      height: '40px'
                    }}/>
                    </span> {item.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default PerfectTrainers;
